import React, { Component } from "react";
import { db } from "./.././firebase";

class CallsReportRestaurant extends Component {
    state = {
        locationId: window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent("location").replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"), // eslint-disable-line
        reportUrl: ''
    }
    async componentDidMount() {
        db.collection('grafannaclient')
            .doc(this.state.locationId)
            .get()
            .then(res => {
                this.setState({
                    reportUrl: res.data()['callsReport']
                })
            })

    }



    render() {
        return (
            <>
                {
                    this.state.reportUrl ?
                        <div class="iframecontainer">
                            {/* <iframe scrolling="yes" style={{ width: '100%', height: '100vh' }} src={this.state.reportUrl} class="iframeclass" ></iframe> */}
                            <iframe  src={this.state.reportUrl} width="100%" height="720px"  frameborder="0"  allowfullscreen > </iframe>
                        </div>
                        // <iframe src={this.state.reportUrl}
                        //     // id="idIframe"
                        //     // frameborder="0" style={{ overflow: 'hidden', width: '100%' }} width="100%"
                        //     style={{ overflow: 'hidden', overflowX: 'hidden', overflowY: 'hidden', height: '100%', width: '100%', position: 'absolute', top: '0px', left: '0px', right: '0px', bottom: '0px' }}
                        //     frameBorder={0} allowFullScreen></iframe>
                        :
                        <></>
                }
            </>

        )
    }
}
export default CallsReportRestaurant;

